//MEDIA QUERIES SIDE REFERENCES
//--custom-media-xs: 480px; // mobile landscape
//--custom-media-sm: 767px; // tablet portrait
//--custom-media-md: 992px; // tablet landscape
//--custom-media-lg: 1200px; // laptop
//--custom-media-xlg: 1366px; // wider screen

//Z-INDEX REFERENCES
//0-99 main page elements
//100-199 popups and fx visible UNDER main navigation
//200-299 navigation
//300-499 popups and fx visible OVER main navigation
//500-699 alerts, toasts, error message

:root[data-theme="light"] {
  --reading-font: Sofia Pro, sans-serif;
  --title-font: FatFrank, Eagle, Lato, sans-serif;

  // Basic theming
  --primary-text-1: #006a52;
  --primary-text-2: #32bf9a;
  --primary-text-3: #36a081;
  --primary-text-4: #006a52;
  --primary-text-3-dark: #004939;
  --primary-text-3-light: #006a52;

  --admin-1: #006a52;
  --admin-2: #ff7b4d;
  --admin-3: #c63030;

  --secondary-text: #004939;
  --secondary-text-light: #32bf9a;

  --default-bg: #ffffff;
  --default-bg-2: #fff9f2;
  --default-bg-3: #fff8e7;
  --default-bg-semitrans: rgba(255, 255, 255, 0.5);
  --default-dark-bg: #fbfbfb;
  --default-darker-bg: #e9e9e9ff;
  --default-color: #515151;
  --default-color-2: #ffffff;
  --default-color-3: #000000;
  --default-color-4: #404040;
  --default-color-5: #dddddd;
  --default-color-6: #6e6e6e;
  --default-color-7: #cecccc;
  --default-color-8: #212121;
  --default-color-9: #fdf7f7;
  --default-color-10: #ededed;
  --troubleshooting-mode: #eeeeee;
  --backdrop: #5e6e8de5;
  --dropdown-bg: #707070;
  --dropdown-all: #707070;

  --bg-semitrans-high-opacity: rgba(255, 255, 255, 0.7);
  --bg-semitrans-low-opacity: rgba(255, 255, 255, 0.2);

  --dropshadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  --dropshadow2: 2px 2px 5px 2px rgba(0, 0, 0, 0.2);
  --dropshadow3: 1px 1px 10px -2px rgba(0, 0, 0, 0.2);
  --dropshadow4: 0 0 10px rgba(0, 0, 0, 0.15);
  --dropshadow5: 0 8px 8px 0 rgba(101, 17, 42, 0.3);
  --dropshadow6: 0 16px 16px 0 rgba(101, 17, 42, 0.3);
  --dropshadow7: 0 4px 4px 0px rgba(101, 17, 42, 0.3);
  --dropshadow8: 0 8px 8px 0px rgba(101, 17, 42, 0.3);

  --horiz-dotted-line-notdone: repeating-linear-gradient(
    to right,
    rgba(0, 0, 0, 0.5) 0,
    rgba(0, 0, 0, 0.5) 1px,
    rgba(255, 255, 255, 0) 2px,
    rgba(255, 255, 255, 0) 6px
  );
  --horiz-dotted-line-orange: repeating-linear-gradient(
    to right,
    rgba(241, 97, 13, 1) 0,
    rgba(241, 97, 13, 1) 2px,
    rgba(255, 255, 255, 0) 2px,
    rgba(255, 255, 255, 0) 8px
  );
  --horiz-dotted-line-yellow: repeating-linear-gradient(
    to right,
    rgba(255, 172, 72, 1) 0,
    rgba(255, 172, 723, 1) 2px,
    rgba(255, 255, 255, 0) 2px,
    rgba(255, 255, 255, 0) 8px
  );

  --side-menu-dropshadow: 1px 7px 5px 1px rgba(0, 0, 0, 0.2);
  --border: #e3e3e3;
  --line-separator: #ececec;
  --line-separator-2: #b4b4b4;
  --scrollbar: #b4b4b4;
  --scrollbar-hover: #979797;
  --ui-disabled: #9b9b9b;
  --ui-disabled-2: #ededed;
  --button-border: #2f2f2f;

  // landing page
  --landing-bg-1: #004939;
  --landing-bg-2: #36a081;
  --landing-bg-3: #f2fefb;
  --landing-troub-logo-bg: #65112a;
  --landing-troub-desc-bg: #f74f4f;
  --landing-cdo-logo-bg: #151433;
  --landing-cdo-desc-bg: #4d5c90;
  --landing-page-login-btn-fg: #ffffff;
  --landing-page-login-btn-bg1: #32bf9a;
  --landing-page-login-btn-bg2: #004939;
  --landing-page-heading-light: #36a081;
  --landing-page-heading-dark: #004939;
  --landing-page-color1: #212121;
  --landing-page-color2: #ffffff;
  --landing-page-scroll-arrow: #36a081;
  --landing-page-popup-close-bg: #00382c;
  --landing-page-popup-close-bg-hover: #515151;
  --landing-page-popup-link: #32bf9a;
  --landing-page-popup-checkbox-bg: #d6d6d6e5;
  --landing-page-popup-checkbox-border: #d6d6d6;

  // Account creation
  --account-creation-bg: #32bf9a;

  // Classroom Management
  --classroom-management-color1: #ffd1b7;
  --classroom-management-color2: #890f1a;
  --classroom-management-color3: #ffe0e0;

  --onboarding-bg: #006a52;
  --onboarding-bg-2: #32bf9a;
  --onboarding-bg-3: #004939;
  --onboarding-bg-4: #00382c;
  --onboarding-bg-5: #cff8ea;
  --onboarding-bg-6: #f2fefb;
  --onboarding-bg-7: #7d5abe;
  --onboarding-bg-8: #52338a;
  --onboarding-bg-9: #eff8ff;
  --onboarding-bg-10: #628efe;
  --onboarding-bg-11: #123289;
  --onboarding-bg-12: #cce3ff;
  --onboarding-bg-13: #3f6fe9;

  --onboarding-constellation-bg: #2081cb;
  --onboarding-constellation-fg: #ffffff;

  // App specific colors (These are to help the users know which apps sections and buttons are associated with.
  // They should be the same across themes)
  --troubadour-main: #f74f4f;
  --troubadour-main-dark: #651124;
  --troubadour-main-2: #651124;
  --troubadour-main-3: #ffa26e;
  --troubadour-main-4: #ba2121;
  --troubadour-main-5: #65112a;
  --troubadour-main-6: #fc6c4d;
  --troubadour-main-7: #ffac48;
  --troubadour-main-8: #4b0a1e;
  --troubadour-main-9: #ff6d4d;
  --troubadour-main-10: #ffe9b5;
  --troubadour-main-11: #350614;
  --troubadour-main-12: #bf6800;
  --troubadour-main-13: #e57d00;
  --troubadour-main-14: #b32c2c;
  --troubadour-main-15: #fff5df;
  --troubadour-main-16: #ffebbe;
  --troubadour-main-17: #ffdc88;
  --troubadour-main-18: #96314f;
  --troubadour-main-19: #451423;
  --troubadour-main-20: #e0648c;
  --troubadour-main-21: #fefbf3;
  --troubadour-main-22: #fff5db;
  --troubadour-main-23: #ffa36e;
  --troubadour-main-24: #ffecfb;
  --constellation-main: #4d5c90;
  --constellation-main-dark: #006c80;
  --constellation-main-2: #151433;
  --constellation-main-3: #433e7e;
  --constellation-main-4: #23224f;
  --constellation-main-5: #5ec5b2;
  --admin-main: #006a52;
  --admin-main-dark: #004939;
  --admin-main-darker: #00382c;
  --admin-main-2: #32bf9a;
  --admin-main-3: #004939;
  --admin-main-4: #36a081;
  --admin-main-5: #ebfffa;
  --admin-main-6: #f7fdfb;
  --admin-main-7: #73bda7;
  --admin-main-8: #8ddbe5;
  --admin-main-9: #075d6d;

  --troubadour-gradient-button-bg: radial-gradient(
    117.19% 117.83% at 86.4% 16.87%,
    #ffac48 31.72%,
    #ff7b4d 100%
  );

  --troubadour-main-button-bg: linear-gradient(
    228deg,
    #ff7b4d 37%,
    #f74f4f 100%
  );

  --troubadour-section-bg: linear-gradient(
    225deg,
    #ff7b4d 42.96%,
    #f74f4f 77.73%
  );

  --troubadour-section-bg2: linear-gradient(
    342deg,
    #ff7d4f 18.86%,
    #f74f4f 77.96%
  );

  --nav-primary-link: #006a52;
  --nav-primary-link-adm: #006a52;
  --nav-primary-link-troub: #f74f4f;
  --nav-primary-link-const: #4d5c90;
  --nav-lower-primary-link: #006a52;
  --nav-sidemenu-link-hover-bg: rgba(0, 182, 141, 0.1);
  --nav-sidemenu-link-troub-hover-bg: rgba(247, 79, 79, 0.1);
  --nav-sidemenu-link-const-hover-bg: rgba(94, 197, 178, 0.1);
  --nav-sidemenu-lower-link-hover-bg: rgba(0, 182, 141, 0.1);
  --nav-social-links: #004939;
  --top-nav-greeting: #9b9b9b;
  --top-nav-heading: #006a52;
  --top-nav-separator: #dddddd;
  --nav-filter-color: hue-rotate(80deg);

  --input-border: #ededed;
  --input-text: #9b9b9b;

  // Packages
  --aren: #4b67db;
  --package-news: #2ecff8;

  // Activity indicator
  --activity-none: #bebebe;
  --activity-very-low: #f92d2d;
  --activity-low: #f9a72d;
  --activity-average: #2eb7e2;
  --activity-high: #43d586;

  // Content validation
  --content-neutral: #767676;
  --content-valid: #2f896e;
  --content-valid-light: #74d9be;
  --content-warning: #ffac48;
  --content-invalid: #c12b58;
  --content-invalid-light: #f59595;

  //Showcase header
  --adm-showcase-header-left-bg: #126651;
  --adm-showcase-header-right-bg: #004939;

  // States
  --valid: #00c779;
  --warning: #fda63e;
  --disabled: #aeb2c2;
  --disabled-border: #a0a0a0;
  --disabled-2: #aeb2c2;

  // Students
  --student-archived: #f56e6eff;
  --student-pending: #f99f18;
  --button-removed: #d3353e;

  // Family
  --family-fancy-text-field-fg: #fdc4b5;
  --family-radio-field-bg: #fffcf5;

  //Media queries Sizes
  --custom-media-xs: 480px; // mobile landscape
  --custom-media-sm: 767px; // tablet portrait
  --custom-media-md: 992px; // tablet landscape
  --custom-media-lg: 1200px; // laptop
  --custom-media-xlg: 1366px; // wider screen

  //region Common components that are shared through apps

  // Left menu
  --left-menu-color: #647483;
  --left-menu-hover: #41585c;
  --left-menu-hover-bg: #091e420f;
  --left-menu-selected-bg: #35a7c11e;

  // Popups
  --popup-error: #f53a54;
  --popup-error-dark: #c20018;
  --popup-success: #1ed27c;
  --popup-default: #36a081;
  --popup-important: #f5223b;
  --popup-warning: #f28300;
  --popup-delete-bg: #fff8f8;

  // News popup
  --news-nanomonx-bg: linear-gradient(
    230.76deg,
    #006a52 -48.08%,
    #36a081 78.22%
  );
  --news-troubadour-bg: linear-gradient(
    141.02deg,
    #c63030 7.94%,
    #310d0d 323.14%
  );
  --news-constellation-bg: linear-gradient(
    230.76deg,
    #1d1a47 -1.35%,
    #4d5c90 78.22%
  );

  --news-nanomonx-action-button-bg: #ff7a66;
  --news-troubadour-action-button-bg: #ff7a66;
  --news-constellation-action-button-bg: linear-gradient(
    141.02deg,
    #53819c 7.94%,
    #5ec4b2 323.14%
  );

  --news-nanomonx-action-button-icon-bg: #ffa26e;
  --news-troubadour-action-button-icon-bg: #ffa26e;
  --news-constellation-action-button-icon-bg: #5ec5b2;

  --news-nanomonx-close-button: #ff7a66;
  --news-troubadour-close-button: #ff7a66;
  --news-constellation-close-button: #ff7a66;

  --news-nanomonx-close-mobile-button: #36a081;
  --news-troubadour-close-mobile-button: #fc6c4d;
  --news-constellation-close-mobile-button: #717eab;

  --news-backdrop: rgba(30, 30, 30, 0.8);

  // quote form popup
  --quote-form-dark: #52338a;
  --quote-form-med: #934bf4;
  --quote-form-light: #7d5abe;

  // Breadcrumbs
  --breadcrumb-text-1: #32bf9a;
  --breadcrumb-text-2: #ffffff;
  --breadcrumb-success: #1ed27c;

  // Buttons
  --button-light-second-color: #ffffff;
  --button-heavy-second-color: #ffffff;
  --button-alt-third-color: #ffffff;
  --button-disabled-bg: #aeb2c2;
  --button-disabled-color: #ffffff;

  --button-action: #36a081;
  --button-action-2: #006a52;
  --button-info: #36a081;
  --button-news: #36a081;
  --button-top-bar: #009cb4;
  --button-back: #009cb4;
  --button-signup: #4c4fa7;
  --button-onboarding: #006a52;
  --button-download: #1ed27c;
  --button-uc-not-started: #44bb65;
  --button-uc-resend: #c92a33;
  --button-uc-revision: #f9b957;
  --button-uc-private: #c20018;
  --button-uc-done: #476eb7;
  --button-excell: #1e6e43;
  --button-contest: #00873e;
  --button-download-pwd: #ff7a66;
  --button-add-group: #ffac48;

  --button-save: #1ed27c;
  --button-delete: #d3353e;
  --button-cancel: #d3353e;
  --button-send: #36a081;

  // Tab container
  --tab-container-bg: #e9e9e9ff;
  --tab-container-bg-hover: #606569ff;
  --tab-container-header-bg: #ebebeb;

  // Toast
  --toast-error: #f53a54;
  --toast-info: #009cb4;
  --toast-success: #1ed27c;
  --toast-warning: #f28300;

  // Filters
  --filter-group-1: #009cb4;
  --filter-group-2: #2d91d8;

  // Lexicon popup
  --lexincon: #26aaaf;
  --lexincon-hover: #3992b6;

  // Pagination
  --pagination-color: #ffffff;
  --pagination-bg: #006a52;
  --pagination-hover: #004939;

  // Quill
  --quill-color-active: #30c1e5;
  --quill-button-bg-active: #43c6e63f;
  --quill-button-bg: #e0e0e08c;
  --quill-color: #5a5a5aff;

  // Standalone Copyright Footer
  --copyright-link-color-admin: #36a081;
  --copyright-link-color-const: #433e7e;
  --copyright-link-color-troub: #f74f4f;

  //License page
  --package-bg: #edfcf8;
  --package-bg-2: #004939;
  --troubadour-bg: #65112a;
  --package-text-color: #006a52;
  --package-details-color-1: #36a081;
  --package-details-color-2: #32bf9a;
  --package-contrast: #ffffff;
  --package-reduction-bg: #fff2eb;
  --package-reduction-text-color: #ff7a66;
  --package-reduction-contrast: #f74f4f;
  --package-reduction-details-color-1: #ffa26e;
  --package-infopanel-expiration: #fff5df;
  --package-payment-modes-button-fg: #212121;
  --package-payment-modes-button-bg: #ffffff;
  --package-payment-modes-button-bd: #2f2f2f;
  --package-learn-more-button-fg: #212121;
  --package-learn-more-button-bg: #ffffff;
  --package-buy-button-fg: #212121;
  --package-buy-button-bg-1: #ffac48;
  --package-buy-button-bg-2: #fc6c4d;

  --package-promo-bg-1: #ff7b4d;
  --package-promo-bg-2: #f74f4f;
  --cart-checkout-btn-bg: #006a52;
  --cart-custom-form-select-bg: rgba(0, 0, 0, 0);
  --cart-province-selector-bg: #c4c4c4;

  // Information Pages Popups
  --info-pages-popup-bg: #f7fdfb;
  --info-pages-popup-close-fg: #ffffff;
  --info-pages-popup-close-bg: #32bf9a;
  --info-pages-popup-close-hover-bg: #006a52;
  --info-pages-popup-scroll-thumb: #32bf9a;
  --info-pages-popup-scroll-track: #d0f0e8;
  --info-pages-popup-scroll-thumb-hover: #00382c;
  --info-pages-popup-heading: #36a081;
  --info-pages-popup-text: #404040;

  // Licence Popup
  --package-redeem-code-color-1: #075d6d;
  --package-redeem-code-color-2: #49caab;
  --package-redeem-code-color-3: #a8ebdb;
  //endregion

  // Communications Popups
  // Turquiose Theme
  --comms-popup-turquoise-color-1: #3ab1c1;
  --comms-popup-turquoise-color-2: #075d6d;
  --comms-popup-turquoise-color-3: #8ddbe5;
  --comms-popup-turquoise-color-4: #cfedb9;
  // Orange Theme
  --comms-popup-orange-color-1: #fc6c4d;
  --comms-popup-orange-color-2: #890f1a;
  --comms-popup-orange-color-3: #ba2121;
  --comms-popup-orange-color-4: #e45434;
  --comms-popup-orange-color-5: #ffac48;
  --comms-popup-orange-color-6: #ffdc88;
  // Yellow Theme
  --comms-popup-yellow-color-1: #ffac48;
  --comms-popup-yellow-color-2: #ba2121;
  --comms-popup-yellow-color-3: #f74f4f;
  --comms-popup-yellow-color-4: #890f1a;
  --comms-popup-yellow-color-5: #e57d00;
  --comms-popup-yellow-color-6: #ff7b4d;
  // Red Theme
  --comms-popup-red-color-1: #f74f4f;
  --comms-popup-red-color-2: #890f1a;
  --comms-popup-red-color-3: #ffffff;
  --comms-popup-red-color-4: #ba2121;
  --comms-popup-red-color-5: #ffdc88;

  // Set School Popup
  --set-school-popup-color-1: #3ab1c1;
  --set-school-popup-color-2: #075d6d;
  --set-school-popup-color-3: #cef2f4;
}
