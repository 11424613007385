@import url("../../components/Buttons/TroubadourButtonTemplate.less");

.troubadour-button {
  .troubadour-button-template();
}

.invite-accept-btn {
  .troubadour-button-template(var(--default-color-8), var(--babillard-color-22));
}

.invite-refuse-btn {
  .troubadour-button-template(var(--default-color-8), var(--babillard-color-17));
}

.google-connect-button {
  .troubadour-button-template(var(--default-color), var(--default-bg));

  box-shadow: var(--dropshadow) !important;
  padding-left: 10px !important;
  padding-right: 10px !important;

  .icon {
    margin-right: 14px !important;
    width: 20px !important;
    height: 20px !important;
    min-width: 20px !important;
    min-height: 20px !important;
  }

  .button-text {
    color: var(--default-color-3) !important;
    max-width: 300px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 30px;
    font-family: Roboto, sans-serif !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    padding: 2px 0 0 !important;
  }

  &:hover {
    &::before {
      background-color: var(--default-bg) !important;
    }

    text-decoration: underline !important;

    .icon {
      transform: scale(1) !important;
    }
  }
}

.google-default-button {
  .troubadour-button-template(var(--default-color), var(--default-bg));

  .button-text {
    max-width: 200px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  box-shadow: var(--dropshadow) !important;

  &:hover {
    text-decoration: none !important;
  }

  &.alt-state {
    pointer-events: none !important;

    &::before {
      background-color: var(--classroom-management-color1) !important;
      border-color: var(--classroom-management-color1) !important;
    }

    .button-text {
      font-size: smaller !important;
      line-height: 1em !important;

      span {
        display: block;
        font-weight: 600;
        font-size: smaller !important;
      }
    }
  }
}

.action-button:not(.troubadour-button) {
  .troubadour-button-template(var(--default-bg), var(--button-action));

  .button-text {
    color: var(--default-color-2) !important;
  }

  &:hover {
    .button-text {
      color: var(--button-action) !important;
    }
  }
}

.bottom-toast-button {
  .troubadour-button-template(var(--workpage-toast-button-fg), var(--workpage-toast-button-bg));
}

.connection-button {
  .troubadour-button-template(var(--landing-page-login-btn-fg), var(--landing-page-login-btn-bg1));
}

.troub-connection-button {
  .troubadour-button-template(var(--default-color-2), var(--troubadour-main-button-bg));
}

.login-button {
  .troubadour-button-template(var(--button-login), var(--default-bg));
}

.showcase-button {
  .troubadour-button-template(var(--default-color), var(--default-color-2));
}

.postpone-button {
  .troubadour-button-template(var(--admin-main-4), var(--default-bg), var(--default-color-2));

  &:hover {
    .button-text {
      text-decoration: underline;
    }
  }
}

.generic-red-arrow {
  .troubadour-button-template(var(--default-color-8), var(--default-color-2));

  .icon {
    width: 20px !important;
    height: 20px !important;
  }
}

.generic-trans-white-btn {
  .troubadour-button-template(var(--default-color-2), transparent, var(--default-color-2));

  &:hover {
    &::before {
      background-color: var(--default-color-2);
    }

    .button-text {
      color: var(--troubadour-main-5) !important;
    }
  }
}

.generic-trans-darkred-btn {
  .troubadour-button-template(var(--troubadour-main-5), transparent, var(--troubadour-main-5));

  &:hover {
    &::before {
      background-color: var(--troubadour-main-5);
    }

    .button-text {
      color: var(--default-color-2) !important;
    }
  }
}

.generic-white-btn {
  .troubadour-button-template(var(--default-color-8), var(--default-bg), var(--default-color));

  &:hover {
    &::before {
      filter: brightness(1) !important;
      background-color: var(--default-color-7) !important;
      border-color: var(--default-color-7) !important;
    }
  }
}

.generic-green-btn {
  .troubadour-button-template(var(--default-color-2), var(--admin-main-4));
}

.generic-medgreen-btn {
  .troubadour-button-template(var(--default-color-2), var(--admin-main-2));

  &.trash-can-button {
    .button-text {
      color: var(--default-color-8) !important;
    }
  }
}

.generic-lightgreen-btn {
  .troubadour-button-template(var(--default-color-2), var(--button-action));
}

.generic-medlightgreen-btn {
  .troubadour-button-template(var(--default-color-2), var(--tutorial-color-14));
}

.generic-vlightgreen-btn {
  .troubadour-button-template(var(--tutorial-color-10), var(--student-challenges-color-2));
}

.generic-orange-btn {
  .troubadour-button-template(var(--default-color-2), var(--troubadour-main-6));
}
.generic-lightorange-btn {
  .troubadour-button-template(var(--default-color-2), var(--troubadour-main-23));
}

.generic-darkorange-btn {
  .troubadour-button-template(var(--default-color-2), var(--troubadour-main));
}

.generic-yellow-btn {
  .troubadour-button-template(var(--troubadour-main-5), var(--troubadour-main-7));
}

.generic-medyellow-btn {
  .troubadour-button-template(var(--troubadour-main-5), var(--troubadour-main-17));
}

.generic-lightyellow-btn {
  .troubadour-button-template(var(--troubadour-main-5), var(--troubadour-main-15), var(--troubadour-main-5));
}

.generic-beige-gradient-btn {
  .troubadour-button-template(var(--troubadour-main-5), var(--troubadour-beige-gradient-button-bg));
}

.generic-medred-btn {
  .troubadour-button-template(var(--default-color-2), var(--troubadour-main-4));
}

.generic-darkred-btn {
  .troubadour-button-template(var(--default-color-2), var(--troubadour-main-5));
}

.generic-blue-btn {
  .troubadour-button-template(var(--default-color-2), var(--popup-default));
}

.generic-turquoise-btn {
  .troubadour-button-template(var(--default-color-2), var(--tutorial-color-9));
}

.generic-medblue-btn {
  .troubadour-button-template(var(--default-color-2), var(--tutorial-color-3));
}

.generic-lightblue-btn {
  .troubadour-button-template(var(--admin-main-9), var(--admin-main-8));
}

.generic-green-button {
  .troubadour-button-template(var(--default-color-2), var(--button-info));
}

.generic-purple-btn {
  .troubadour-button-template(var(--default-color-2), var(--onboarding-bg-7));
}

.generic-darkpurple-btn {
  .troubadour-button-template(var(--default-color-2), var(--onboarding-bg-8));
}

.generic-troub-white-btn {
  .troubadour-button-template(var(--troubadour-main-5), var(--default-bg), var(--troubadour-main-5));

  &:hover {
    .troubadour-button-template(var(--default-bg), var(--troubadour-main-5), var(--default-color));
  }
}

.generic-troub-white2-btn {
  .troubadour-button-template(var(--troubadour-main), var(--default-bg), var(--troubadour-main));

  &:hover {
    .troubadour-button-template(var(--default-bg), var(--troubadour-main), var(--default-color));
  }
}

.generic-no-button {
  .troubadour-button-template(var(--default-color-2), var(--troubadour-main-4));

  border: 1px solid var(--default-color) !important;

  .icon {
    background-color: var(--default-bg);
    padding: 5px;
    transform: scale(0.9) !important;
  }
}

.generic-yes-button {
  .troubadour-button-template(var(--default-color-2), var(--admin-main));

  border: 1px solid var(--default-color) !important;

  .icon {
    background-color: var(--default-bg);
    padding: 5px;
    transform: scale(0.9) !important;
  }
}

.freemium-continue-btn {
  .troubadour-button-template(var(--default-color-2), var(--troubadour-main));
}

.form-submit-button,
.add-student-button {
  position: relative;
  padding: 12px 20px 12px 50px;
  border-radius: 45px;
  color: var(--default-color-2);
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  background-color: var(--landing-bg-2);
  box-shadow: var(--dropshadow);
  border: none;
  transition: 250ms all;

  &:hover {
    color: var(--landing-bg-2);
    background-color: var(--default-color-2);
  }

  &::before {
    content: "";
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    width: 36px;
    height: 36px;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.form-submit-button {
  &::before {
    background-image: url("https://constellation-backend-images.s3.ca-central-1.amazonaws.com/production/icons/checkmark-light-green.svg");
  }
}
.add-student-button {
  &::before {
    background-image: url("https://constellation-backend-images.s3.ca-central-1.amazonaws.com/production/icons/buy-cart-plus-green.svg");
  }
}

.cancel-button {
  .troubadour-button-template(var(--default-color-2), var(--troubadour-main), var(--troubadour-main));
}

// Type: Pill
.generic-darkred-pill-btn {
  .troubadour-button-template(var(--default-color-2), transparent, var(--default-color-2), var(--troubadour-main-5));
}

.generic-orange-pill-btn {
  .troubadour-button-template(var(--troubadour-main-5), transparent, var(--troubadour-main-5), var(--troubadour-main-9));
}

.generic-orange-gradient-pill-btn {
  .troubadour-button-template(var(--default-color-2), var(--troubadour-main-button-bg), var(--troubadour-main-button-bg));

  &:hover {
    .troubadour-button-template(var(--default-color-2), var(--troubadour-main-button-bg));
  }
}

.troub-gradient-btn {
  .troubadour-button-template(var(--troubadour-main-5), var(--troubadour-gradient-button-bg));
}

.troub-darkorange-gradient-btn {
  .troubadour-button-template(var(--default-color-2), var(--troubadour-gradient-button-bg));
}

.troub-darkorange-gradient2-btn {
  .troubadour-button-template(var(--default-color-2), var(--troubadour-gradient2-button-bg));
}

.troub-challenge-card-btn {
  width: 100%;

  &::before {
    box-shadow: var(--dropshadow4) !important;
  }

  &.troub-card-read {
    .troubadour-button-template(var(--onboarding-bg-13), var(--default-bg));
  }
  &.troub-card-write {
    .troubadour-button-template(var(--babillard-color-1), var(--default-bg));
  }
  &.troub-card-correct {
    .troubadour-button-template(var(--tutorial-color-14), var(--default-bg));
  }
  &.troub-card-share {
    .troubadour-button-template(var(--troubadour-main-20), var(--default-bg));
  }
  &.troub-card-options {
    .troubadour-button-template(var(--student-challenges-color-12), var(--default-bg));
  }

  &:hover {
    &.troub-card-read {
      .troubadour-button-template(var(--default-bg), var(--onboarding-bg-13));
    }
    &.troub-card-write {
      .troubadour-button-template(var(--default-bg), var(--babillard-color-1));
    }
    &.troub-card-correct {
      .troubadour-button-template(var(--default-bg), var(--tutorial-color-14));
    }
    &.troub-card-share {
      .troubadour-button-template(var(--default-bg), var(--troubadour-main-20));
    }
    &.troub-card-options {
      .troubadour-button-template(var(--default-bg), var(--student-challenges-color-12));
    }
  }

  &:hover {
    &::before {
      border: 2px solid var(--default-color-2) !important;
    }
  }
}

.troub-radial-gradient-btn {
  .troubadour-button-template(var(--troubadour-main-5), var(--troubadour-radial-button-bg));
}
