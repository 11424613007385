.nanotoast-container {
  position: fixed;
  padding: 15px 40px;
  border-radius: 6px;
  z-index: 500;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.15);
  margin: 15px 0;
  display: flex;
  opacity: 0;

  &.info {
    background: var(--toast-info);
  }

  &.warning {
    background: var(--toast-warning);
  }

  &.success {
    background: var(--toast-success);
  }

  &.error {
    background: var(--toast-error);
  }

  &.has-button {
    .inner-div {
      p,
      i {
        font-size: 17px;
      }
    }
  }

  .toast--close {
    position: absolute;
    top: 5px;
    right: 10px;
    color: var(--default-color-2);
    font-size: 16px;

    &:hover {
      color: var(--default-color);
      cursor: pointer;
    }
  }

  .inner-div {
    display: flex;
    margin: 0 auto;
    align-items: center;

    p {
      text-align: center;
      padding: 0;
      font-size: 16px;
      font-weight: bold;
      color: var(--default-color-2);
      margin: auto 0;
      word-break: break-word;

      br {
        display: none;
      }
    }

    i {
      color: var(--default-color-2);
      font-size: 21px;
      margin: auto 10px auto auto;
    }

    button {
      background: none;
      white-space: nowrap;
      border: 2px solid var(--default-color-2);
      color: var(--default-color-2);
      font-size: 16px;
      font-weight: bold;
      border-radius: 6px;
      padding: 10px 17px;
      margin-left: 15px;
      transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
    }

    button:hover {
      cursor: pointer;
      background: rgba(255, 255, 255, 0.1);
    }
  }

  &.nanotoast-top-left {
    top: -100px;
    left: 0;
  }

  &.nanotoast-top-center {
    top: -100px;
    left: 10%;
    width: 80%;
  }

  &.nanotoast-top-right {
    top: -100px;
    right: 15px;
  }

  &.nanotoast-bottom-left {
    left: 0;
    bottom: -100px;
  }

  &.nanotoast-bottom-center {
    width: 40%;
    max-width: 40%;
    left: 30% !important;

    transform: translate(0, 0);
    margin-left: 0;

    &.with-menu {
      width: calc(40% - 70px);
      max-width: calc(40% - 70px);
      left: calc(30% + 70px) !important;
    }

    &.pushed-fixed-ui {
      width: calc(40% - 255px);
      max-width: calc(40% - 255px);
      left: calc(30% + 255px) !important;
    }
  }

  &.nanotoast-bottom-right {
    right: 15px;
    bottom: -100px;
  }
}

@media (max-width: 992px) {
  .nanotoast-container {
    .inner-div {
      margin: 0 auto;
    }
  }
}

@media (max-width: 1366px) {
  .nanotoast-container.nanotoast-bottom-center {
    width: 80%;
    max-width: 80%;
    left: 10% !important;

    &.with-menu {
      width: calc(80% - 70px);
      max-width: calc(80% - 70px);
      left: calc(10% + 70px) !important;
    }

    &.pushed-fixed-ui {
      width: calc(80% - 255px);
      max-width: calc(80% - 255px);
      left: calc(10% + 255px) !important;
    }
  }
}

@media (max-width: 992px) {
  .nanotoast-container.nanotoast-bottom-center {
    width: 80%;
    max-width: 80%;
    left: 10% !important;

    transform: translate(0, 0) !important;
    margin-left: 0 !important;

    &.with-menu {
      width: calc(80% - 70px);
      max-width: calc(80% - 70px);
      left: calc(10% + 70px) !important;
    }

    &.pushed-fixed-ui {
      width: calc(80% - 255px);
      max-width: calc(80% - 255px);
      left: calc(10% + 255px) !important;
    }
  }

  .nanotoast-container.nanotoast-top-center.success {
    left: 10%;
    width: 80%;
  }
}

@media (max-width: 767px) {
  .nanotoast-container {
    font-size: 15px !important;

    &.nanotoast-bottom-center {
      width: 80%;
      max-width: 80%;
      left: 10% !important;

      transform: translate(0, 0) !important;
      margin-left: 0 !important;

      &.with-menu {
        width: calc(80% - 70px);
        max-width: calc(80% - 70px);
        left: calc(10% + 70px) !important;
      }

      &.pushed-fixed-ui {
        width: calc(80% - 255px);
        max-width: calc(80% - 255px);
        left: calc(10% + 255px) !important;
      }
    }

    &.nanotoast-top-center {
      padding: 15px 15px 25px;

      &.pushed-fixed-ui {
        min-width: 0;
        max-width: calc(90vw - 68px);
        width: auto !important;
        padding: 21px 14px;
        left: calc(10vw / 2 + 68px) !important;
      }

      .inner-div {
        flex-direction: row;
        flex-wrap: wrap;

        i {
          margin: 0 auto;
        }

        p {
          margin: 15px 0;
          font-size: 16px;
        }

        a {
          button {
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .nanotoast-container.nanotoast-bottom-center {
    width: 90%;
    max-width: 90%;
    left: 5% !important;

    transform: translate(0, 0) !important;
    margin-left: 0 !important;

    &.with-menu {
      width: calc(90% - 70px);
      max-width: calc(90% - 70px);
      left: calc(5% + 70px) !important;
    }

    &.pushed-fixed-ui {
      width: calc(90% - 255px);
      max-width: calc(90% - 255px);
      left: calc(5% + 255px) !important;
    }
  }

  .nanotoast-container.nanotoast-top-center.success {
    max-width: calc(95vw - 68px);
    padding: 21px 10px;
    left: calc(5vw / 2 + 68px) !important;

    .inner-div {
      p {
        font-size: 12px;
      }
    }
  }
}
